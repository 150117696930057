import * as Yup from "yup"

export const orderFormValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  emailBody: Yup.string().required("This field is required"),
  // uploadFiles: Yup.object().shape({
  //   path: Yup.string().required("Please upload the necessary file"),
  // }),
  // uploadFiles: Yup.string().when("uploadFiles", {
  //   is: (file) => file.path,
  //   then: Yup.string().required("Please upload the necessary file(s)"),
  // }),
})

export const orderFormInitialValues = {
  fullName: "",
  email: "",
  emailBody: "",
}
